import React, { useState, useEffect } from 'react';
import { MAIN_CONFIG } from '../../../config/main';
import DrawnArrow from '../SVG/DrawnArrow';
import Close from '../SVG/Close';
import './login-modal.scss'
import { handleKeyDown } from '../../Utility/ApplicationFormUtility';

export default ({
    handleClose = () => { },
    handleLogin = () => { },
    roles,
    authDetail,
    hideClose = false
}) => {

    /**
       * Renders the available accounts.
       * @param {object[]} accounts
       * @returns {*}
       */
    const renderAccounts = (roleData) => {
        return roleData.map((account, index) => {
            return (
                <li tabIndex={0} onKeyDown={(e)=>handleKeyDown(e,()=>handleLogin(account, authDetail))} key={`account-switcher-${index}`} onClick={() => handleLogin(account, authDetail)}>
                    <a href={'#'} tabIndex={-1}>
                        <div className='account-switcher__account'>
                            <div className='account-switcher__left'>
                                <div className='CTABlack account-switcher__company'>{(account === 'School Counsellor') ? 'School Counselor' : account}</div>
                            </div>

                            <DrawnArrow className='account-switcher__arrow' color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel="Right Arrow" ariaLabelDescription={`Click here to login as ${account}`} />
                        </div>
                    </a>
                </li>
            );
        });
    };

    return (
        <div id='login-role-modal-content'>
            <div className="login-role-head_container">
                <div>
                    &nbsp;&nbsp;&nbsp;
                </div>
                <div id="login-as__title" className='H1DesktopGreen'>
                    Login As
                </div>
                <div>
                    {!hideClose && <a tabIndex={0} className="event-head H6DesktopGrey" aria-label="Close" onKeyDown={(e)=>handleKeyDown(e,()=>handleClose(e))} onClick={handleClose}><Close tabIndex={-1} color={MAIN_CONFIG.COLORS.fillGrey} ariaLabel="Close Modal box" ariaLabelDescription="" /></a>}
                </div>
            </div>

            <div className="login-role-applications">


                <ul id='account-switcher__accounts'>
                    {renderAccounts(roles)}
                </ul>

            </div>
        </div>
    )
}