import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGrey, width = "200", height = "143", secondaryColor = MAIN_CONFIG.COLORS.fillRed, ariaLabel = "Image Placeholder", ariaLabelDescription = "Image Placeholder" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 143">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <defs>
                <clipPath id="clip-ImagePlaceholder">
                    <rect width="200" height="143" />
                </clipPath>
            </defs>
            <g id="ImagePlaceholder" clipPath="url(#clip-ImagePlaceholder)">
                <g id="noun_Image_1269202" transform="translate(-4 -17)">
                    <path id="Path_7780" data-name="Path 7780" d="M204,17H4V160H204Zm-8.7,8.667V130.945L152.63,87.287l-29.2,25.047-48-37.44L12.7,136.167V25.667ZM12.7,151.333v-3.077l63.37-61.923,47.63,37.18,28.413-24.332,43.2,44.178v7.973Z" transform="translate(0 0)" fill={color} />
                    <path id="Path_7781" data-name="Path 7781" d="M90.115,68.57A21.785,21.785,0,1,0,68.33,46.785,21.785,21.785,0,0,0,90.115,68.57Zm0-34.856A13.071,13.071,0,1,1,77.044,46.785,13.071,13.071,0,0,1,90.115,33.714Z" transform="translate(75.39 9.428)" fill={color} />
                </g>
            </g>
        </svg>
    );
};
